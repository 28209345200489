/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, Button, Group, Image, Modal, Text } from '@mantine/core'
import React, { useEffect, useRef, useState } from 'react'

import { getSession, getStorage, setSession, setStorage } from '@/lib/utils'
import { Feature as FeatureType } from '@/types'

interface Props {
  feature: FeatureType
  allFeatures: FeatureType[]
}

export default function Popup({ feature, allFeatures }: Props) {
  // Hooks
  const contentRef = useRef<HTMLDivElement>(null)

  // Constants
  const storageKey = `feature.display-${feature.uid}`
  const { display = 'always', persistent = false } = feature.config
  const firstPopup =
    allFeatures
      .filter(feat => {
        const featStorageKey = `feature.display-${feat.uid}`
        return (
          feat.config?.display &&
          (feat.config.display === 'always' ||
            (feat.config.display === 'once' && !getStorage(featStorageKey)) ||
            (feat.config.display === 'session' && !getSession(featStorageKey)))
        )
      })
      .findIndex(feat => feat.uid === feature.uid) === 0

  // States
  const [open, setOpen] = useState(false)

  // Actions
  const close = () => {
    setOpen(false)
    if (display === 'once') setStorage(storageKey, true)
    else if (display === 'session') setSession(storageKey, true)
  }

  useEffect(() => {
    if (!open && firstPopup) {
      if (display === 'once') setOpen(!getStorage(storageKey))
      else if (display === 'session') setOpen(!getSession(storageKey))
      else setOpen(display === 'always')
    }
  }, [display, firstPopup])

  useEffect(() => {
    if (contentRef) {
      const btnClose = contentRef.current?.querySelector('#btn-comprar')
      if (btnClose) btnClose.addEventListener('click', close)
    }
  }, [contentRef])

  return (
    <>
      {persistent && (
        <Alert variant="light">
          <Group position="apart">
            <Text fw="bold">{feature.title || 'Abrir popup'}</Text>
            <Button onClick={() => setOpen(true)}>Ver mais</Button>
          </Group>
        </Alert>
      )}
      <Modal
        opened={true}
        onClose={close}
        title={feature.title}
        centered
        size="xl"
        withinPortal={false}
        lockScroll={false}
        zIndex={400}
        sx={{ display: open ? 'block' : 'none' }}>
        {feature.image && <Image src={feature.image} alt={feature.title} />}
        <Box ref={contentRef} dangerouslySetInnerHTML={{ __html: feature.content }} />
      </Modal>
    </>
  )
}
