import 'dayjs/locale/pt-br'

import {
  ActionIcon,
  Badge,
  Box,
  FileInput,
  Input,
  MultiSelect,
  NumberInput,
  Select,
  SelectItem,
  Switch,
  Text
} from '@mantine/core'
import { IconFile, IconPaperclip, IconX } from '@tabler/icons'
import Link from 'next/link'
import React from 'react'

import { toBase64 } from '@/lib/utils'
import { MaskedPhoneNumberWithCode, MaskedTaxDocumentCPF } from '@/lib/utils/masks'

interface Props {
  config: object
  description?: string
  isRequired: boolean
  name: string
  options: SelectItem[]
  type: string
  uid?: string
  value: any
  showLabel?: boolean
  onChange: (value: any) => void
  inputProps: object
}

const fieldTypes = ({
  config,
  isRequired,
  name,
  options,
  type,
  value,
  onChange,
  inputProps
}: Props) => {
  let parsedValue = value
  let mask: any = {}
  if (type === 'datetime' && value) {
    const datetimeValue = new Date(value)
    parsedValue = datetimeValue.setHours(datetimeValue.getHours() - 3)
  }
  if (type === 'phone') mask = { component: MaskedPhoneNumberWithCode }
  if (type === 'cpf') mask = { component: MaskedTaxDocumentCPF }
  switch (type) {
    case 'str':
    case 'email':
    case 'phone':
    case 'cpf':
      return (
        <Input
          type={type === 'email' ? 'email' : 'text'}
          placeholder={name}
          required={isRequired}
          value={value || ''}
          {...config}
          onChange={(e: any) => onChange(e.target.value)}
          {...mask}
          {...inputProps}
        />
      )
    case 'int':
      return (
        <Input
          type="number"
          placeholder={name}
          required={isRequired}
          value={value || ''}
          {...config}
          onChange={e => onChange(e.target.value)}
          {...inputProps}
        />
      )
    case 'float':
      return (
        <NumberInput
          placeholder={name}
          required={isRequired}
          value={value || ''}
          decimalSeparator="."
          hideControls
          {...config}
          onChange={newValue => onChange(newValue)}
          {...inputProps}
        />
      )
    case 'bool':
      return (
        <Switch
          label={name}
          checked={value}
          required={isRequired}
          {...config}
          onChange={e => onChange(e.target.checked)}
          {...inputProps}
        />
      )
    case 'date':
      return (
        <Input
          type="date"
          placeholder={name}
          required={isRequired}
          value={value || ''}
          {...config}
          onChange={e => onChange(e.target.value)}
          {...inputProps}
        />
      )
    case 'datetime':
      return (
        <Input
          type="datetime-local"
          placeholder={name}
          required={isRequired}
          value={parsedValue ? new Date(parsedValue).toISOString().replace('Z', '') : ''}
          {...config}
          onChange={e => {
            const newValue = e.target.value
            onChange(newValue ? new Date(newValue).toISOString() : '')
          }}
          {...inputProps}
        />
      )
    case 'file':
      return (
        <Box>
          <FileInput
            icon={<IconFile />}
            // @ts-ignore
            placeholder={name}
            required={isRequired}
            description="Limite 3mb"
            value={value && typeof value !== 'string' ? value : null}
            {...config}
            onChange={async file => {
              if (file?.size && file.size / 1048576 > 3) {
                alert('Arquivo deve ser menor que 3mb')
              } else if (file) onChange(await toBase64(file))
            }}
            {...inputProps}
          />
          {value && typeof value === 'string' && (
            <Badge
              rel="noreferrer noopener"
              px={5}
              mt="xs"
              leftSection={
                <ActionIcon color="custom" size="xs">
                  <IconPaperclip />
                </ActionIcon>
              }
              rightSection={
                <ActionIcon
                  color="custom"
                  size="xs"
                  onClick={e => {
                    e.stopPropagation()
                    onChange(null)
                  }}>
                  <IconX />
                </ActionIcon>
              }
              sx={{ cursor: 'pointer' }}>
              <Text
                component={Link}
                href={value.indexOf('data:') === -1 ? value : ''}
                target="_blank">
                {value.indexOf('data:') === -1
                  ? value.match?.(/([^/])*(?=\?AWS)/)?.[0]
                  : 'Arquivo selecionado'}
              </Text>
            </Badge>
          )}
        </Box>
      )
    case 'select':
      return (
        <Select
          placeholder={name}
          data={options}
          required={isRequired}
          value={value ? (typeof value === 'string' ? value : value?.uid) : null}
          onChange={option => onChange(option)}
          {...config}
        />
      )
    case 'multi_select':
      return (
        <MultiSelect
          placeholder={name}
          data={options}
          required={isRequired}
          multiple
          value={
            value
              ? value?.flatMap((option: any) => (typeof option === 'string' ? option : option.uid))
              : null
          }
          onChange={option => onChange(option)}
          {...config}
        />
      )
  }
}

export default function CustomField({
  config,
  description,
  isRequired,
  name,
  options,
  type,
  uid,
  value,
  showLabel,
  onChange,
  inputProps
}: Props) {
  const hiddenField = ['address']
  return hiddenField.indexOf(type) === -1 ? (
    <Input.Wrapper
      id={uid}
      withAsterisk={isRequired}
      label={showLabel && type !== 'bool' ? name : null}
      description={description && type !== 'bool' ? description : ''}
      inputWrapperOrder={['label', 'input', 'description', 'error']}>
      {fieldTypes({
        config,
        isRequired,
        name,
        options,
        type,
        value,
        onChange,
        inputProps
      })}
    </Input.Wrapper>
  ) : null
}
